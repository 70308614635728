'use client'
import { FC } from 'react'
import { Box, HStack, VStack, SimpleGrid } from '@chakra-ui/react'
import { useSelector } from 'shared-redux'
import Thumbnails from './Thumbnails'
import CategoryWithSubCategories from './CategoryWithSubCategories'
import { ContentContainer, GrandNextLink, GrandText } from 'ui'
import {
  selectedMenuCategories,
  selectedMenuCategory,
  selectedMenuCategoryId
} from 'shared-redux/state'
import appConfig from '#/src/appConfig'
import { sortCategories } from 'shared-utils'

export interface ISlideOutCategoriesProps {}

const SlideOutCategories: FC<ISlideOutCategoriesProps> = () => {
  const category = useSelector(selectedMenuCategory)
  const menuCategories = sortCategories([...useSelector(selectedMenuCategories)])
  const parentCategoryId = useSelector(selectedMenuCategoryId)

  return (
    <ContentContainer borderColor="primary" borderTopWidth="1px" minH="20vh" py={0} w="100%">
      <HStack align="flex-start" py={6} spacing={2} w="100%">
        <VStack flex={1} spacing={0}>
          <Box w="full">
            <GrandNextLink
              aria-label={'category ' + category?.toUpperCase()}
              fontSize="sm"
              href={`${appConfig.paths.categoriesUrlPath}/${parentCategoryId}`}>
              <GrandText pb={4}>{category?.toUpperCase()}</GrandText>
            </GrandNextLink>
          </Box>
          <Box w="full" maxH="60vh" overflowY="auto">
            <SimpleGrid columns={{ base: 1, md: 5 }} spacing={4} w="full">
              {menuCategories.map?.((category, index) => (
                <Box key={index + '-navCategoryGroup1-' + category?.id}>
                  <CategoryWithSubCategories {...{ category }} />
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        </VStack>

        {appConfig.menuCategoriesThumbnails === false ? null : (
          <Thumbnails flex={0} {...{ menuCategories }} />
        )}
      </HStack>
    </ContentContainer>
  )
}

export default SlideOutCategories

import { Grape_Nuts, Roboto, Roboto_Condensed, Kameron, Moul, Open_Sans } from 'next/font/google'

const roboto = Roboto({
  subsets: ['latin', 'latin-ext'],
  weight: ['400', '500', '700', '900']
})

const robotoCondensed = Roboto_Condensed({
  subsets: ['latin', 'latin-ext'],
  weight: ['400', '700']
})

const grapeNuts = Grape_Nuts({
  subsets: ['latin', 'latin-ext'],
  weight: ['400']
})

const kameron = Kameron({
  subsets: ['latin', 'latin-ext'],
  weight: ['400', '500', '700']
})

const openSans = Open_Sans({
  subsets: ['latin', 'latin-ext'],
  weight: ['400', '500', '700']
})

const moul = Moul({
  subsets: ['latin'],
  weight: ['400']
})

export const fonts = {
  // default fonts
  heading: robotoCondensed.style.fontFamily,
  body: roboto.style.fontFamily,
  // custom fonts
  slogan: grapeNuts.style.fontFamily,
  landingBody: openSans.style.fontFamily,
  landingHeading: moul.style.fontFamily,
  landingSlogan: kameron.style.fontFamily
}

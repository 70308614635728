'use client'
import { FC } from 'react'
import { SlideOutComponent, SlideOutFooter } from '#/src/custom/controllers/SlideOutChildren'
import { SlideOutContext, SlideOutStoreController, useSlideOut } from '..'

const SlideOutHandler: FC = () => {
  const { componentId, extraArgs, footerId } = useSlideOut()

  return (
    <SlideOutStoreController
      context={SlideOutContext}
      footer={<SlideOutFooter {...{ footerId }} />}
      topGap="8vh">
      <SlideOutComponent {...{ componentId, extraArgs }} />
    </SlideOutStoreController>
  )
}

export default SlideOutHandler
